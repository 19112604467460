<template>
  <v-tooltip bottom open-delay="1000">
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" icon @click="$router.push(to)">
        <v-icon>mdi-arrow-left </v-icon>
      </v-btn>
    </template>
    <span>{{ $t('label.return_to_list') }}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: "ReturnToList",
  props: ['to'],
};
</script>
